import {
  Box,
  Button,
  Container,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { HomeAnimationProps } from "@definitions/types"
import heroPhone from "@public/images/hero-phone.png"
import heroPhoneRTL from "@public/images/hero-phone-rtl.png"
import bgEffects from "@public/lottie/bg-effects.json"
import { motion } from "framer-motion"
import Image from "next/image"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"
import Lottie from "react-lottie-player/dist/LottiePlayerLight"

const MotionBox = motion.create(Box)

export default function HomeAnimation({ title, cta_text }: HomeAnimationProps) {
  const { locale } = useRouter()
  const titleX = locale === "ar" ? -10 : 10
  const titleY = useBreakpointValue({ base: 120, lg: "50%" })
  const heroPhoneStartX = locale === "ar" ? -800 : 800
  const heroTitleStartX = locale === "ar" ? 500 : -500
  const t = useTranslations("home.hero")

  return (
    <>
      <Container
        maxW={"container.xl"}
        position={"relative"}
        width={"full"}
        height={"100vh"}
        overflow={"hidden"}
        zIndex={1}
      >
        <MotionBox
          position={"relative"}
          opacity={0}
          animate={{
            top: [titleY, titleY],
            opacity: [0, 1],
            translateX: [heroTitleStartX, titleX],
            translateY: "-50%",
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            times: [0, 1],
          }}
          overflow={"hidden"}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
            fontWeight={"bold"}
            color={"white"}
            lineHeight={1.3}
            maxW={"md"}
          >
            {title ?? t("hero_text")}
          </Text>
          <Button
            color={"brand.dark"}
            mt={{ base: 4, md: 10 }}
            p={{ base: 4, md: 6 }}
            as={NextLink}
            href={cta_text ? `/contact` : `/why-emenu`}
            shadow={"md"}
            bg={"white"}
            size={{ base: "sm", md: "md" }}
          >
            {cta_text ?? t("button_text")}
          </Button>
        </MotionBox>
      </Container>
      <Box
        position={"absolute"}
        width={"full"}
        height={{ base: "60%", sm: "65%", lg: "80%" }}
        bottom={0}
        right={locale === "ar" ? "auto" : 0}
        left={locale === "ar" ? 0 : "auto"}
        overflow={"hidden"}
      >
        <MotionBox
          position={"relative"}
          width={"full"}
          height={"full"}
          opacity={0}
          animate={{
            opacity: [0, 1],
            translateX: [heroPhoneStartX, 0],
            translateY: [600, 0],
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            times: [0, 1],
          }}
        >
          <Image
            src={locale === "ar" ? heroPhoneRTL : heroPhone}
            loader={({ src }) => src}
            alt={"phone"}
            sizes={"50vw"}
            placeholder={"blur"}
            priority
            fill
            style={{
              objectFit: "contain",
              objectPosition: `bottom ${locale === "ar" ? "left" : "right"}`,
            }}
          />
        </MotionBox>
      </Box>
      <Box display={{ base: "none", md: "block" }}>
        <Lottie
          loop={true}
          play={true}
          animationData={bgEffects}
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            position: "absolute",
            transform: `${locale === "ar" ? "scaleX(-1)" : "scaleX(1)"}`,
          }}
        />
      </Box>
    </>
  )
}
